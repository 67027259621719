<template>
    <v-chip
        class="table-label"
        small
        label
        dark
        :color="statusColor"
    >
        <div>{{text}}</div>
    </v-chip>
</template>

<script>
export default {
    name: "DocumentTypeChip",
    props: {
        type: {
            type: String,
            default: ""
        },
        text: {
            type: String,
            default: ""
        }
    },
    computed:
    {
        statusColor()
        {
            switch (this.type)
            {
                case "OutgoingDocument":
                    return "#7289DA";

                case "InnerDocument":
                    return "#00AEEF";

                case "IncomingDocument":
                    return "#8DC63F";

                case "EuolStatement":
                    return "#F47B68";

                case "Resolution":
                    return "#7289DA";

                case "ActItem":
                    return "#00AEEF";

                case "Order":
                    return "#00AEEF";

                case "IQalaOrder":
                    return "#7289DA";

                case "Substitution":
                    return "#F04747";

                case "ProtocolDocument":
                    return "#FAAE2D";

                case "NPADocument":
                    return "#9D81BA";

                default:
                    return "#7289DA";
            }
        }
    }
}
</script>